/* Icon set - http://ionicons.com/ */
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);

.testimonials-main-container {
    width: 100%;
    overflow: hidden;
    background-color: var(--main-bg-color);
}

.testimonials-carousel-container {
    display: flex;
    width: 200%;
    animation: testimonialsMove 20s linear infinite;
    flex-direction: row;
}

.testimonials-carousel-container:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.testimonials-carousel-container-div {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.testimonials-card {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 20px;
  width: 400px;
  color: #333;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.testimonials-card * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.testimonial-card-img {
  max-width: 100%;
  vertical-align: middle;
}

.testimonial-card-quote {
  position: relative;
  background-color: #ffffff;
  padding: 25px 50px 25px 50px;
  font-size: 0.8em;
  font-weight: 500;
  text-align: left;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
  height: 140px;
}

.testimonial-card-quote:before,
.testimonial-card-quote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}

.testimonial-card-quote:before {
  top: 25px;
  left: 20px;
}

.testimonial-card-quote:after {
  content: "\201D";
  right: 20px;
  bottom: 0;
}

.testimonials-card .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ffffff;
  margin: 0;
  position: absolute;
}

.testimonials-author-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 25px;
  color: #000000;
  background-color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}

.testimonials-author-name {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}

.testimonials-author-company {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}



@keyframes testimonialsMove {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
    }
    

@media only screen and (min-width : 320px) {
  .testimonials-card {
    width: 300px;
  }

  .testimonial-card-quote {
    height: 200px;
  }

  .testimonials-carousel-container-div {
    width: auto;
  }
}
.course-form-container {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.courseform-items-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.course-form-title {
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin: 20px 5% 10px 5%;
    text-align: center;
}

.course-form-duration {
    color: white;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
}

.course-form-dates {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
}

.course-form-item {
    margin: 10px 5% 10px 5%;
    padding: 10px 0 10px 0;
    border-radius: 20px;
    box-shadow: none;
    text-align: center;
}

.course-form-terms-conditions {
    color: var(--main-text-color);
    margin: 20px 5% 20px 5%;
    font-size: 14px;
}

.course-form-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
    padding: 20px 0 20px 0;
    border-radius: 20px;
    border-color: white;
    box-shadow: none;
    color: var(--main-btn-txt-color);
    font-weight: 700;
    background-color: white;
    cursor: pointer;
}

.course-form-btn:hover {
    background-color: var(--main-btn-border-color-hover) ;
    color: white;
}

.course-form-load-spinner {
    height: 40px;
    width: 40px;
}
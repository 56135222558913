.scrum-master-main-container {
    position: relative;
    top:70px;
    background-color: var(--main-bg-color);
}

.course-goals-block{
    display: flex;
    flex-direction: column;
    margin: 10px 10vw 30px 10vw;
}

.course-goals-title{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    color: var(--titles-color);
}

.course-goals-content{
    display: flex;
    flex-direction: column;
}

.course-goals-line1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 40px;
}

.course-goals-line2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.course-goal-item {
    display: flex;
    flex-direction: row;
}

.course-item-icon{
    width: 70px;
    height:fit-content;
    margin-right: 20px;
    background-color: white;
}

.course-item-text{
    width: 15vw;
}

.goal-item-title{
    font-size: 30px;
    font-weight: 700;
    color: var(--subtitles-color);
}

.goal-item-description {
    font-size: 20px;
    font-weight: 400;
    color: var(--main-text-color);
}

.course-included-list-certificate-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.course-included-block {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 9vh 10vw 0px 10vw;
    background-color: var(--main-bg-color);
}

.course-included-title {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: var(--titles-color);
}

.course-included-text{
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    color: var(--main-text-color);
}

.course-included-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-left: 20px;
    align-items: left;
}

.course-included-item {
    font-size: 18px;
    font-weight: 300;
    margin: 5px 0 5px 0;
    color: var(--main-text-color);
}

.course-included-certificate-img {
    width: auto;
    height: 30vh;
    margin-left: 10px;
}


.course-certification-about-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4vh 10vw 5vh 10vw;
    background-color: var(--main-bg-color);
}

.course-certification-about-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: white;
    padding: 0 2vw 4vh 2vw;
}

.course-certification-about-content {
    display: flex;
    flex-direction: row;
}

.course-certification-about-badge {
    height: 25vh;
    width: auto;
}

.course-more-info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    background-image: url("./../imgs/Brand/AX_Background.webp");
}

.course-more-info-text {
    font-size: 50px;
    font-weight: 700;
    color: white;
}

.course-more-info-btn {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 35px;
    cursor: pointer;
    background-color: var(--main-btn-bg-color);
    border: 2px solid var(--main-btn-border-color);
    border-radius: 50px;
    color: var(--main-btn-txt-color);
    text-decoration: none;
}

.course-more-info-btn:hover {
    background-color: var(--secundary-btn-bg-color);
    border: 2px solid var(--main-btn-border-color);
    color: var(--main-btn-txt-color-hover);
    text-decoration: none;
}

.course-trainer-info-block {
    width: 100vw;
    background-color: var(--main-bg-color);
}

.course-trainer-info-title {
    padding: 10vh 5vw;
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    color: var(--titles-color);
    text-align: center;
}


.course-community-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9vh 10vw 30px 10vw;
    background-color: var(--main-bg-color);
}

.course-community-title{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: var(--titles-color);
}

.course-community-text{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: var(--main-text-color);
}

@media (max-width: 600px)  {

    .course-goals-block{
        display: flex;
        flex-direction: column;
        margin: 10px 10vw 0px 10vw;
    }

    .course-goal-item {
        align-items: center;
    }

    .course-goals-content{
        flex-direction: column;
    }

    .course-goals-line1 {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0px;
    }

    .course-goals-line2 {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .course-item-text {
        width: 60vw;
        margin-bottom: 30px;
    }

    .course-item-icon{
        height: 70px;
        width:auto;
        background-color: none;
    }

    .course-included-title {
        font-size: 40px;
        font-weight: 700;
    }

    .course-included-list-container {
        margin-left: 0px;
    }

    .course-included-list-certificate-container {
        display: flex;
        flex-direction: column;
    }

    .course-included-certificate-img {
        margin-left: 0;
        width: 80vw;
        height: auto;
        margin-top: 1vh;
    }

    .course-certification-about-content {
        align-items: center;
        flex-direction: column;
    }

    .course-certification-about-badge {
        height: auto;
        width: 60vw;
    }

    .course-more-info-text {
        font-size: 30px;
        font-weight: 500;
        color: white;
        text-align: center;
    }

    .course-trainer-info-title {
        padding: 10vh 5vw 5vh 5vw;
        margin: 0;
        font-size: 30px;
        font-weight: 700;
        color: var(--titles-color);
        text-align: center;
    }

    .course-community-block {
        padding: 2vh 10vw 30px 10vw;
    }
}

.course-topics-block {
    display: flex;
    flex-direction: column;
    margin: 10px 10vw 30px 10vw;
    color: var(--main-text-color);
}

.course-topics-title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: var(--titles-color);
}

.course-topics-subtitle {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: var(--subtitles-color);
    margin-bottom: 30px;
}

.course-topics-description {
    margin-bottom: 30px;
}

.course-topics-text {
    font-size: 20px;
    font-weight: 400;
    color: var(--main-text-color);
    text-align: justify;
}

.course-topics-img {
    height: 30vw;
    margin-right: 40px;
}
.course-topics-container {
    display: flex;
    flex-direction: column;
}

.course-topics-content {
    display: flex;
    flex-direction: row;
}

.course-topics-bullets{
    display: flex;
    flex-direction: column;
}

.course-topics-list-container {
    margin-top: 30px;
    margin-left: 20px;
}

.course-topic-item {
    font-size: 18px;
    font-weight: 300;
    margin: 5px 0 5px 0;
}

@media (max-width: 600px)  {
    .course-topics-content {
        flex-direction: column;
    }
    .course-topics-img {
        width: 100%;
        height: auto;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .course-topics-list-container {
        margin-left: 0px;
        margin-bottom: 40px;
    }
}
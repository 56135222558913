.diff¡culty-main-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid green;
    background-color:green;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin: 0 5px 0 5px;
}

.signal-icon {
    height: 18px;
    width: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

  .signal-icon .signal-bar {
    width: 4px;
    opacity: 30%;
    background: white;
  }
  
  .signal-icon .signal-bar:nth-child(1) { height: 40%; }
  .signal-icon .signal-bar:nth-child(2) { height: 70%; }
  .signal-icon .signal-bar:nth-child(3) { height: 100%; }
  
  .signal-icon.weak .signal-bar:nth-child(1),
  .signal-icon.medium .signal-bar:nth-child(1),
  .signal-icon.medium .signal-bar:nth-child(2),
  .signal-icon.strong .signal-bar:nth-child(1),
  .signal-icon.strong .signal-bar:nth-child(2),
  .signal-icon.strong .signal-bar:nth-child(3)
  { opacity: 100%; }

  .difficulty-level-text {
    font-size: 1rem;
    color: white;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

@media (max-width: 600px)  {
  .diff¡culty-main-container{
    margin: 0 0 0 5px;
  }
}

.academy-content-main-container {
    background-color: var(--main-bg-color);
}

.academy-content-container {
    display: flex;
    flex-direction: column;
}

.academy-content-description-container {
    display: flex;
    flex-direction: row;
    margin-top: 10vh;
}

.academy-content-description {
    text-align: left;
    color: var(--subtitles-color);
    font-size: 4rem;
    font-weight: 700;
    margin: 2vh 5vw 2vh 2vw;
}

.academy-content-icon {
    width: auto;
    height: 20vw;
    margin: 2vw;
}

.academy-pilar-section-title{
    color: var(--subtitles-color);
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    margin-top: 15vh;
}

.academy-content-pilars-container {
    display: flex;
    flex-direction: row;
    margin: 2vh 2vw 5vh 2vw;
    justify-content: space-between;
}

.academy-pilar-box {
    border: 1px solid var(--main-box-border-color);
    width: 30vw;
    border-radius: 5px;
}

.academy-pilar-box-container {
    display: flex;
    flex-direction: column;
    margin: 5%;
    justify-content: center;
    align-items: center;
}

.academy-pilar-title {
    color: var(--subtitles-color);
    font-weight: 700;
}

.academy-pilar-description {
    color: var(--main-text-color);
    font-size: 20px;
    font-weight: 400;
    margin: 0 2vw 0 2vw;
    text-align: center;
}

.academy-conditions-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10vh 2vw 5vh 2vw;
}

.academy-conditions-section-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
}

.academy-conditions-section-img {
    height: 40vh;
    width: auto;
}

.academy-conditions-container {
    display: flex;
    flex-direction: column;
}

.academy-conditions-title {
    color: var(--titles-color);
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.academy-conditions-bullet {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2vw;
}

.academy-conditions-bullet-img {
    width: 20px;
    height: auto;
    margin-right: 10px;
}

.academy-conditions-bullet-text {
    font-size: 20px;
    font-weight: 400;
    color: var(--main-text-color);
    margin: 10px;
}

.academy-conditions-btn {
    padding: 10px 70px 10px 70px;
    width:fit-content;
    border-radius: 20px;
    font-weight: 700;
    background-color: var(--secundary-btn-bg-color);
    border: 1px solid var(--main-btn-border-color);
    color: var(--main-btn-txt-color-hover);
    cursor: pointer;
}

.academy-conditions-btn:hover {
    border-radius: 20px;
    background-color: var(--main-btn-bg-color);
    color: var(--secundary-btn-txt-color);
}

.academy-plans-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10vh 2vw 10vh 2vw;
}

.academy-plans-section-title {
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    color: var(--subtitles-color);
    margin: 0;
}

.academy-plans-section-intro {
    color: var(--main-text-color);
    font-size: 40px;
    font-weight: 400;
}

.academy-plans-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.academy-plans-content-box {
    display: flex;
    flex-direction: column;
    width: 25vw;
    margin-top: 8vh;
    justify-content: space-between;
}

.academy-plans-content-box:nth-child(2) {
    padding: 0 0 0 2vw;
    border-left: 1px dashed var(--main-box-border-color);
    border-right: 1px dashed var(--main-box-border-color);
}

.academy-plans-content-box:nth-child(3) {
    padding: 0 2vw 0 2vw;
}

.academy-plans-content-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.academy-plans-box-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5vw;
}

.academy-plan-header-name {
    color: var(--main-text-color);
    margin: 0;
}

.academy-plan-header-cost {
    color: var(--titles-color);
    margin-top: 10px;
}

.academy-plan-header-img {
    height: 10vh;
    width: auto;
}

.academy-plan-details-container {
    height: max-content;
    width: 100%;
    margin-bottom: 10vh;
}

.academy-plan-detail-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.academy-plan-detail-item-bullet-icon {
    height: auto;
    width: 20px;
}

.academy-plan-detail-item-text {
    color: var(--main-text-color);
    margin: 5px;
}

.academy-plan-detail-btn {
    padding: 10px 70px 10px 70px;
    width:fit-content;
    border-radius: 20px;
    font-weight: 700;
    background-color: var(--secundary-btn-bg-color);
    border: 1px solid var(--main-btn-border-color);
    color: var(--main-btn-txt-color-hover);
    cursor: pointer;
}

.academy-plan-detail-btn:hover {
    border-radius: 20px;
    background-color: var(--main-btn-bg-color);
    color: var(--secundary-btn-txt-color);
}

.academy-plan-detail-personalized {
    display: flex;
    flex-direction: column;
}

.academy-plan-detail-personalized-text {
    color: var(--main-text-color);
    font-size: 20px;
    font-weight: 400;
}

@media (max-width: 600px)  { 

    .academy-content-title {
        text-align: center;
        font-size: 40px;
        margin: 5vh 2vw 2vh 2vw;
    }
    .academy-content-description-container {
        flex-direction: column;
        align-items: center;
    }
    .academy-content-description {
        font-size: 2rem;
        margin: 0 5vw 2vh 5vw;
    }
    .academy-content-icon {
        width: 90vw;
        height: auto;
    }
    .academy-content-pilars-container {
        flex-direction: column;
        margin: 5vh 2vw 3vh 2vw;
        align-items: center;
    }

    .academy-pilar-section-title {
        font-size: 40px;
    }

    .academy-pilar-box {
        width: 90vw;
        margin-bottom: 2vh;
    }

    .academy-conditions-section-main {
        flex-direction: column;
    }
    .academy-conditions-section-img {
        width: 90vw;
        height: auto;
        margin-bottom: 5vh;
    }

    .academy-plans-content-container {
        flex-direction: column;
        margin-top: 5vh;
    }

    .academy-plans-section-title {
        margin-bottom: 2vh;
    }

    .academy-plans-section-intro {
        color: var(--main-text-color);
        font-size: 25px;
        font-weight: 400;
        text-align: center;
        margin: 2vh 2vw 0 2vw;
        
    }

    .academy-plans-content-box {
        flex-direction: column;
        width: 80vw;
        margin-top: 3vh;        
    }

    .academy-plan-details-container {
        margin-bottom: 5vh;
    }

    .academy-plans-content-box:nth-child(2) {
        padding: 2vh 0 2vh 0;
        border-top: 1px dashed var(--main-box-border-color);
        border-bottom: 1px dashed var(--main-box-border-color);
        border-right: 0px;
        border-left: 0px;
    }
}
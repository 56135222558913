.phone {
    display: flex;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 290px;
    width: 70%;
    transform: translateY(5%);
  }

  .light, .dark { 
    font-size: 90%;
  }
  
  label, .toggle {
    height: 2.8rem;
    border-radius: 100px;
    bottom: 0;
  }

  .label-toogle {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(0,0,0,.1);
    border-radius: 100px;
    position: relative;
    margin: 1.8rem 0 4rem 0; 
    cursor: pointer;
  }
  
  .toggle {
    position: absolute;
    width: 50%;
    background-color: green;
    box-shadow: 0 2px 15px rgba(0,0,0,.15);
    transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  
  .names {
    font-size: 90%;
    font-weight: bolder;
    width: 65%;
    margin-left: 17.5%;
    margin-top: .5%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    user-select: none;
  }
  
  .dark {
    opacity: .5;
  }
  
  .light {
    color: white;
  }
  
  /* -------- Switch Styles ------------*/
  /* [type="checkbox"] {
    display: none;
  } */
  /* Toggle */
  [type="checkbox"]:checked + .app .toggle{
    transform: translateX(100%);
    background-color: green;
  }
  
  [type="checkbox"]:checked + .app .dark{
    opacity: 1;
    color: white;
  }
  
  [type="checkbox"]:checked + .app .light{
    opacity: .5;
    color:black;
  }
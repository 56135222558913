.footer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: var(--main-bg-color);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.grupo-1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 45px;
    /* margin: 10px; */
    justify-content: space-between;
}

.footer-block-title {
    text-align: center;
    color: var(--titles-color);
    margin-bottom: 25px ;
    font-size: 20px;
}

.logo-footer{
    width: 300px;
    cursor: pointer;
}

.footer-block-left {
    display: flex;
    align-items: end;
}

.footer-block-left-figure{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.corporate-link {
    color: white;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    padding: 0;
    cursor: pointer;
}

.corporate-link a{
    text-decoration: none;
    color: white;
}

.footer-block-center {
    text-align: left;
}

.footer-block-right {
    text-align: left;
}

.social-network a{
    display: inline-block;
    text-decoration: none;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-right: 10px;
    background-color:white;
    text-align: center;
    transition: all 300ms ease;
    color: var(--social-icons-color);
}

.social-network a:hover{
    color: var(--social-icons-color-hover);
}

.corporate-link a:hover{
    color: var(--nav-menu-color-hover);
}

.footer .grupo-2 {
    padding: 15px 10px;
    text-align: center;
    color: white;
}

.footer .grupo-2 small {
    font-size: 15px;
}

@media(max-width: 600px){
	.grupo-1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .footer-block-center {
        text-align: center;
    }
    
    .footer-block-right {
        text-align: center;
    }
    
}
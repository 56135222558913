.banner-routes-main-container {
    padding-top: 75px;
    display: flex;
    max-width: 100%;
    height: max-content;
    padding-bottom: 4vh;
    background-image: url(./../imgs/CareerRoutes/Bubbles.png);
    background-color: var(--main-bg-color);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: left;
}

.banner-routes-secundary-container {
    padding-top: 2vh;
    margin: 0 10vw;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
}

.banner-routes-content-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.banner-routes-icon {
    height: 10vh;
    width: auto;
    margin-right: 1vw;
}

.banner-routes-content-text-container {
    display: flex;
    flex-direction: column;
}

.banner-routes-title {
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: 400;
    color: var(--titles-color);
    padding: 0;
    margin: 0;
}

.banner-routes-route-name {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3rem;
    color: white;
    font-weight: bold ;
    padding: 0 0 1vh 0;
    margin: 0;
}

.banner-routes-text-description {
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 200;
    color: white;
}

.banner-routes-btn {
    display: flex;
    margin-top: 30px;
}

.banner-routes-btn1 {
    border: var(--main-btn-border-color) solid 2px;
    border-radius: 25px;
    padding: 15px 30px 15px 30px;
    margin-right: 20px ;
    text-align: center;
    text-decoration: none;
    color: black;
    font-weight: 700;
    background-color: var(--btn-bg-color-hover);
    cursor: pointer;
}

.banner-routes-btn1:hover {
    background-color: black;
    color: white;
}

@media (max-width: 600px)  {
    .banner-routes-main-container  {
        height:max-content;
        padding-bottom: 2vh;
    }

    .banner-routes-secundary-container {
        padding-top: 0;
    }

    .banner-routes-content-container {
        flex-direction: column;
    }

    .banner-routes-icon {
        height: auto;
        width: 10vw;
        margin-bottom: 1vh;
    }

    .banner-routes-title {
        font-size: 1.2rem;
    }

    .banner-routes-route-name {
        font-size: 2rem;
    }
}
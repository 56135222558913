.infinite-carousel-main-container {
    width: 100%;
    overflow: hidden;
}

.infinite-carousel-img {
    display: block;
    width: 100%;
    border-radius: 16px;
}

.infinite-carousel-container {
    display: flex;
    width: 200%;
    animation: bannermove 20s linear infinite;
}

.infinite-carousel-container.paused {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.infinite-carousel-container-div {
    width: 100%;
}

.infinite-carousel-img-items {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

.infinite-carousel-img-item {
    width: 100%;
    margin: 20px;
}

.infinite-carousel-img-item:nth-child(odd) {
    transform: rotate(-3deg);
}

.infinite-carousel-img-item:nth-child(even) {
    transform: rotate(3deg);
}

@keyframes bannermove {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(-50%);
}
}

@media (max-width: 600px)  {
    .infinite-carousel-img-item {
        margin: 8px;
    }
    .infinite-carousel-img {
        border-radius: 10px;
    }
    .infinite-carousel-container {
        width: 300%;
    }
}
.business-metrics-main-container {
    display: flex;
    justify-content: center;
    padding-top: 6vh;
    margin-left: 0;
    /* background-image: url("./../imgs/BackgroundHex.jpg"); */
    background-color: var(--main-bg-color);
    background-repeat: no-repeat;
    background-size: cover;
}

.business-metrics-container {
    display: flex;
    margin: 4vw;
    flex-direction: row;
}

.business-metrics-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18vw;
    height: 18vw;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 2vw;
}

.business-metrics-card:hover {
    background: rgba(255, 255, 255, 0.5);
}

.business-metrics-img {
    width: 10vw;
    height: auto;
    overflow: hidden;
}

.business-metric-number {
    color: white;
    font-weight: bold;
    font-size: 2vw;
    padding: 0;
    margin: 0;
}

.business-metric-text {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.2vw;
    padding: 0;
    margin: 0;
}

@media (max-width: 600px)  {
    .business-metrics-container {
        display: flex;
        margin: 4vw;
        flex-direction: column;
    }

    .business-metrics-card {
        width: 60vw;
        height: 60vw;
        margin-bottom: 2vh;
    }

    .business-metrics-img {
        width: 70px;
        height: auto;
        overflow: hidden;
    }
    
    .business-metric-number {
        font-size: 30px;
    }
    
    .business-metric-text {
        font-size: 18px;
    }
}

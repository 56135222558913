.services-slider-main-container {
    display: flex;
    background-color: var(--main-bg-color);
    flex-direction: column;
}

.services-slider-content-container {
    display: flex;
    flex-direction: row;
    padding: 4vh 5vw 6vh 5vw;
    justify-content: center;
}

.services-slider-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slide-unactive {
    display: none;
}

.services-slider-slide-img {
    height: 100px;
    width: auto;
}

.services-slider-slide-title {
    color: var(--subtitles-color);
    text-align: center;
}
.services-slider-slide-text {
    margin: 0px 15vw 0px 15vw;
    color: var(--main-text-color);
    text-align: center;
}

.services-slider-icon-back {
    width: 50px;
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
    cursor: pointer;
}

.services-slider-icon-next {
    width: 50px;
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
    cursor: pointer;
}

.services-slider-slide-stepper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.services-slider-slide-step {
    width: 20px;
    margin: 5px;
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
   
}

@media (max-width: 600px)  {
    .services-slider-slide-text {
        margin: 0px 5vw 0px 5vw;
        color: var(--main-text-color);
        text-align: center;
    }
}
.course-pricing-options-main-container {
  background-color: var(--main-bg-color);
  min-height: max-content;
  padding: 2vh 0 5vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-pricing-options-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 2vw 0 2vw;
  color: var(--green-main);
}

.course-pricing-options-subtitle {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 4rem;
    color: var(--main-text-color);
    color: white;
}

.course-pricing-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  align-items: center;

  .course-pricing-plan-card {
    align-items: center;
    background-color: #fff;
    padding: 2rem;
    margin: 12px;
    margin-bottom: 2vh;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    .course-pricing-plan-title {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .price {
      margin-bottom: 1rem;
      font-size: 30px;
    }

    ul.features {
      list-style-type: none;
      text-align: left;
      li {
        margin: 8px;
        .fas {
          margin-right: 4px;
        }
        .fa-check-circle {
          color: var(--green-main);
        }
        .fa-times-circle {
          color: #eb4d4b;
        }
      }
    }

    .course-pricing-btn {
      border: none;
      width: 100%;
      padding: 12px 35px;
      margin-top: 1rem;
      background-color: var(--green-main);
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
    }

    &.popular {
      border: 2px solid var( --green-main);
      position: relative;
      transform: scale(1.08);
      max-width: 100%;
      padding: 4vh 1vw 4vh 1vw;

      span {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var( --green-main);
        color: #fff;
        padding: 4px 20px;
        font-size: 18px;
        border-radius: 5px;
      }
    }

    &:hover {
      box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
    }
  }
}

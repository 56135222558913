.course-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20vw;
    min-height: max-content;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 2vi 0 2vi;
}

.course-card-img-block{
    border-radius: 50%;
    overflow: hidden;
    margin-top: 1vh;
}

.course-card-img {
    width: 120px;
    height: 120px;
}

.course-card-label {
    margin-top: 1vh;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    background-color: green;
    padding: 5px 5px 5px 5px;
    width: auto;
    height: fit-content;
    border-radius: 4px;
    text-align: center;
}

.course-card-title {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--titles-color);
    margin: 10px 10px 5px 10px;
}

.course-card-date {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--subtitles-color);
    margin: 1vi;
}

.course-card-btn{
    background-color: var(--main-btn-bg-color);
    padding: 10px 20px 10px 20px;
    margin: 1vw;
    border-radius: 10px;
    color: var(--secundary-btn-txt-color);
    border: 2px solid var(--main-btn-border-color);
    cursor: pointer;
}

.course-card-btn:hover{
    background-color: var(--btn-bg-color-hover);
    color: var(--main-btn-txt-color-hover);
}

@media (max-width: 600px)  {
    .course-card-container{
        width: 80vw;
        height: auto;
        margin: 3vh 2vw 1vh 2vw;
    }

    .course-card-img-block{
        width: 120px;
        height: 120px;
        border-radius: 60px;
        overflow: hidden;
        margin-top: 3vh;
    }

    .course-card-img {
        width: 120px;
        height: 120px;
    }

    .course-card-btn{
        margin: 5vh;
    }
}
.trainer-profile-main-container {
    width: 100vw;
    max-height: max-content;
    padding: 0 0 10vh 0;
    background-color: black;
}

.trainer-profile-inner-container {
    margin: 0 10vw 0 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.trainer-profile-img {
    width: 30vw;
    height: auto;
    border-radius: 20px 0 20px 0;
}

.trainer-profile-info-block {
    max-width: 40vw;
}

.trainer-profile-info-block-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.trainer-profile-info-name {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: white;
}

.trainer-profile-info-linkedin-icon {
    height: 2.5vh;
    width: auto;
    margin-left: .5vw;
    cursor: pointer;
}

.trainer-profile-info-role {
    padding-top: 0;
    margin-top: 0;
    color: rgba(255, 255, 255, 0.5);
}

.trainer-profile-info-description {
    color: white;
    padding: 0 0 1vh 0;
}

.trainer-profile-items-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5vh;
}

.trainer-profile-item-icon {
    height: 3vh;
    width: auto;
    margin-right: .5vw;
}

.trainer-profile-item-description {
    padding: 0;
    margin: 0;
    color: white;
}

@media (max-width: 600px)  { 

    .trainer-profile-main-container {
        padding: 0 0 2vh 0;
    }

    .trainer-profile-inner-container {
        flex-direction: column;
    }

    .trainer-profile-img {
        width: 80vw;
        height: auto;
        border-radius: 20px 0 50px 0;
        padding-bottom: 2vh;
    }

    .trainer-profile-info-block {
        max-width: 80vw;
    }

    .trainer-profile-item-icon {
        height: 3vh;
        width: auto;
        margin-right: 2vw;
    }

    .trainer-profile-items-container {
        margin-bottom: 2vh;
    }
}
.section-title-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
	background-color: var(--main-bg-color);
}

.section-title-text {
	position: relative;
	padding: 0;
	margin: 15 0 0 0;
	font-family: 'Helvetica', sans-serif;
	font-weight: 300;
	font-size: 40px;
	color:var(--titles-color);
	-webkit-transition: all 0.2s ease 0s;
	-o-transition: all 0.2s ease 0s;
	transition: all 0.2s ease 0s;
  }
  
  .section-title-text em {
	font-style: normal;
	font-weight: 600;
  }

  .section-title-text {
	text-align: center;
	padding-bottom: 0.7em;
  }
  
  .section-title-text:before {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 60px;
	height: 1px;
	content: "";
	left: 50%;
	margin-left: -30px;
	background-color: var(--titles-color);
	transform: scale(1);
  }

.section-title-text:hover {
	transform: scale(1.08);
  }

.course-presentation-container{
    display: flex;
    background-image: url("./../imgs/background_courses.webp");
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.course-presentation-left{
    width: 40vi;
    margin: 10px 40px 20px 40px;
}

.video-youtube{
    width: 40vi;
}

.course-presentation-quick-info-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.course-presentation-label {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: green;
    padding: 5px 5px 5px 5px;
    width: auto;
    height: fit-content;
    border-radius: 4px;
    text-align: center;
    margin: 0 10px 0 0;
}

.course-presentation-title {
    color: white;
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 10px;
}

.course-presentation-text {
    color: white;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 40px;
}

.course-presentation-right{
    width: 30vi;
    margin: 10px 40px 20px 10vi;
}

@media (max-width: 600px)  {
    .course-presentation-container{
        flex-direction: column;
    }

    .course-presentation-title {
        font-size: 35px;
        text-align: left; 
    }

    .course-presentation-left{
        width: 80vi;
    }

    .course-presentation-right{
        width: 80vi;
    }
    .video-youtube{
        width: 80vi;
    }

    .course-presentation-label {
        margin: 0 5px 0 0;
    }
}
.lading-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ball {
	width: 2vw;
	height: 2vw;
	border-radius: 100%;
	margin: 1vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.ball1 {
	background-color: orange;
}

.ball2 {
	background-color: orange;
	animation-delay: 0.1s;
}

.ball3 {
	background-color: orange;
	animation-delay: 0.2s;
}

.ball4 {
	background-color: orange;
	animation-delay: 0.3s;
}

@keyframes bounce {
	0%, 50%, 100% {
		transform: scale(1);
		filter: blur(0px);
	}
	25% {
		transform: scale(0.6);
		filter: blur(3px);
	}
	75% {
		filter: blur(3px);
		transform: scale(1.4);
	}
}

@media (max-width: 600px)  {

	.ball {
		width: 3vw;
		height: 3vw;
		margin: 2vw;
	}
}

.menu {
  position: fixed;
  bottom: 100px;
  right: 20px;
  padding: 30px;
  background: #e8e8f3;
  border-radius: 100%;
  cursor: pointer;
}
.menu::before, .menu::after {
  content: "";
  background: #c3c2c7;
  border-radius: 2px;
  width: 20px;
  height: 5px;
  position: absolute;
  left: 21px;
  top: 27px;
  transition: 0.2s ease;
  z-index: 1;
}
.menu::before {
  transform: rotate(0deg);
}
.menu::after {
  transform: rotate(-90deg);
}
.menu.open {
  opacity: 0.9;
}
.menu.open::before {
  transform: rotate(45deg);
}
.menu.open::after {
  transform: rotate(-45deg);
}
.menu.open .button {
  opacity: 1;
  pointer-events: auto;
}
.menu.open .button:first-of-type {
  bottom: 40px;
  right: 70px;
  background: url("./../imgs/cideBot.svg") no-repeat 50%/50% 

#e8e8f3;
}
.menu.open .button:nth-of-type(2) {
  bottom: 80px;
  background: url("./../imgs//whatsapp.svg") no-repeat 50%/50% 

#e8e8f3;
  transition-delay: 0.05s;
}
.menu.open .button:last-of-type {
  bottom: -40px;
  right: 70px;
  background: url("./../imgs/mailus.svg") no-repeat 50%/50% 

45% #e8e8f3;
  transition-delay: 0.1s;
}

.button {
  padding: 30px;
  border-radius: 50%;
  cursor: pointer;
  background: #e8e8f3;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  box-shadow: inherit;
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity, 

0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
}
.button:hover {
  transform: scale(1.1);
}

.menu:hover {
  background: green;
  border: 1px solid white;
}
.certificate-validator-main-container {
    display: flex;
    flex-direction: column;
    padding: 1vh 3vw 1vh 3vw;
    background-color: var(--main-bg-color);
}

.certificate-validator-welcome-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vh;
}

.certificate-validator-welcome-box-title {
    font-weight: bold;
    color: var(--titles-color);
}

.certificate-validator-welcome-box-description {
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
    color: var(--main-text-color);
}


.certificate-validator-search-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7vh;
}

.certificate-validator-search-box-title {
    font-weight: bold;
    color: var(--main-text-color);
    margin-bottom: 3vh;
}

.certificate-validator-search-box-form-input {
    width: 30vw;
    padding: 8px;
    border: 2px solid var(--main-btn-border-color-hover);
}

.certificate-validator-search-box-form-button {
    padding: 8px;
    border-radius: 0 7px 7px 0;
    background-color: var(--main-btn-bg-color);
    color: var(--secundary-btn-txt-color);
    font-weight: bold;
    border: 2px solid var(--main-btn-border-color);
}

.certificate-validator-search-box-form-button:hover {
    padding: 8px;
    border-radius: 0 7px 7px 0;
    background-color: var(--btn-bg-color-hover);
    color: var(--main-btn-txt-color-hover);
    font-weight: bold;
    border: 2px solid var(--main-btn-border-color-hover);
}

.certificate-validator-loading {
    font-size: large;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.certificate-validator-not-found {
    font-size: large;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    color: var(--main-text-color);
}

.certificate-validator-table-box {
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.certificate-validador-results-table {
    margin-bottom: 2vh;
}

.certificate-validator-title {
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.certificate-validator-table-item {
    text-align: center;
    padding: 3px 0 3px 0;
}

.certificate-validator-table-item-view {
    text-align: center;
}

.certificate-validator-table-item-view:hover {
    text-decoration: underline;
}

@media (max-width: 600px)  {
    .certificate-validator-main-container {
        display: flex;
        flex-direction: column;
        padding: 1vh 5vw 1vh 5vw;
    }

    .certificate-validator-welcome-box-title {
        text-align: center;
    }
    
    .certificate-validator-search-box-form-input {
        width: 60vw;
        padding: 8px;
    }

    .certificate-validator-search-box {
        margin-bottom: 3vh;
    }
}
.abut-us-banner-container{
    padding: 30px;
    background-color: var(--main-bg-color);
}

.block-title {
    font-size: 60px;
    font-weight: 700;
    text-align: left;
    /* color: var(--titles-color); */
    color: white;
}

.block-paragrah {
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    color: var(--main-text-color);
}

.about-us-block1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.block1-text {
    margin-right: 40px;

}

.block1-paragrah {
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--main-text-color);
}

.block1-img img{
    width: 35vw;
    height: auto;
}



.about-us-block2 {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    margin-bottom: 30px;
}

.about-us-block2-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.block2-text {
    padding-left: 4vw;
    padding-right: 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.about-us-block2-right {
    display: flex;
    flex-direction: column;
    width: 45vw;
}

.about-us-block2-right-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.about-us-block2-item-img {
    width: auto;
    height: 120px;
    margin-right: 20px;
}

.about-us-block2-quote {
    margin: 6vh 20vw 8vh 20vw;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    color: var(--main-text-color);
}

.about-us-block2-link {
    margin: 4vh 30vw 5vh 30vw;
    text-align: center;
    font-size: 20px;
    color: var(--titles-color);
    cursor: pointer;
    font-weight: 400;
}

.about-us-block2-link:hover {
    text-decoration: underline;
}

.about-us-block3 {
    display: flex;
    flex-direction: row;
}

.block3-img img{
    width: 50vi;
    height: auto;
}

.block3-text {
    margin-left: 40px;
}

.about-us-block4 {
    display: flex;
    flex-direction: column;
}

.title-block4 {
    text-align: center;
}
.block4-paragrah {
    margin: 6vh 20vw 8vh 20VW;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: var(--main-text-color);
}

.block4-staff-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.block4-staff-cards-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.block4-staff-card{
    margin-right: 50px;
}

.staff-card-img {
    width: 200px;
    height: 200px;
    object-position: 0;
    overflow: hidden;
    border-radius: 50%;
    filter: grayscale(100%);
   -webkit-filter: grayscale(100%);
}

.staff-card-name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: var(--main-text-color);
}

.block4-staff-cards-origami {
    width: 30vw;
}

.block4-orginame-image {
    width: auto;
    height: 170px;
    margin-right: 20px;
}


@media (max-width: 600px)  {
    .about-us-block1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .block1-text {
        margin: 0px 0px 30px 0px;
    }

    .block1-paragrah {
        font-size: 2.5rem;
        /* text-align: center; */
    }

    .block1-img img{
        width: 100%;
        height: auto;
    }


    .about-us-block2-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about-us-block2-right {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .about-us-block2-item-img {
        width: auto;
        height: 80px;
        margin-right: 20px;
    }

    .about-us-block2-quote {
        margin: 4vh 5vw 8vh 5vw;
        text-align: center;
        font-size: 40px;
        font-weight: 400;
    }
    
    .about-us-block2-link {
        margin: 4vh 5vw 5vh 5vw;
        text-align: center;
        font-size: 20px;
        color: var(--titles-color);
        cursor: pointer;
        font-weight: 400;
    }

    .block2-text {
        margin: 0px 0px 30px 0px;
    }

    .about-us-block3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .block3-text {
        margin: 0px 0px 30px 0px;
    }

    .block3-img img{
        width: 100%;
        height: auto;
    }

    .block4-paragrah {
        margin: 6vh 5vw 5vh 5vw;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: var(--main-text-color);
    }

    .block4-staff-cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .staff-card-img {
        width: 130px;
        height: 130px;
    }

    .block4-staff-card{
        margin: 10px;
    }

    .block4-orginame-image {
        margin-top: 2vh;
        margin-right: 0px;
    }
    
}

.bannerPage-container {
	background: url('./../imgs/scrum.webp');
	text-align: center;
	width: 100%;
	height: 380px;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	overflow: hidden;
}

.bannerPage-container .overlay{
	width: 100%;
	height: 100%;
	padding: 50px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
    /* background-image: linear-gradient( 135deg, orange 2%, #fd5e086b 70%); */
}

.bannerPage-title {
	font-family:  "Playfair Display", serif;
	font-size: 80px;
	margin-bottom: 30px;
	font-weight: 700;
}

.bannerPage-subtitle, .bannerPage-description {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 30px;
}

.bannerPage-btn {
	border: none;
	outline: none;
	padding: 10px 20px;
	border-radius: 50px;
	color: #333;
	background: #fff;
	margin-bottom: 50px;
	box-shadow: 0 3px 20px 0 #0000003b;
}
.bannerPage-btn:hover{
	cursor: pointer;
}

@media (max-width: 600px)  {
	.bannerPage-container {
		height: auto;
		background-size: contain;
		background-repeat: no-repeat;
	}
}
.career-route-carousel-main-container {
    background-color: black;
}

.career-route-carousel-inner-container {
    display: flex;
    flex-direction: row;
    margin: 0 10vw 0 10vw;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    height: max-content;
    padding: 4vh 0 4vh 0;
}

.career-route-carousel-left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 20vw;
    max-width: 20vw;
    min-height: max-content;
    padding: 4vh 1vw 4vh 0;
}

.career-route-carousel-name {
    font-size: 1.5rem;
    padding-bottom: 5px;
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
}

.career-route-carousel-description {
    color: white;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}

.career-route-carousel-cards-container {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

@media (max-width: 600px)  { 
    .career-route-carousel-inner-container {
        flex-direction: column;
        padding: 2vh 0 2vh 0;
    }

    .career-route-carousel-left-container {
        min-width: 80vw;
    }

    .career-route-carousel-cards-container {
        flex-direction: column;
    }

}
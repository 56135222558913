
.infinite-carousel-img {
    display: block;
    width: 100%;
}
  
.infinite-carousel-main-container {
    width: 100%;
    overflow: hidden;
    margin-top: 4vh;
}

.infinite-carousel-container-reverse {
    display: flex;
    width: 200%;
    animation: bannermoveReverse 20s linear infinite;
}

.infinite-carousel-container.paused {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.infinite-carousel-container-div {
    width: 100%;
}

.infinite-carousel-img-items {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

.infinite-carousel-img-item {
width: 100%;
}

.infinite-carousel-img-item:nth-child(2) {

}

.infinite-carousel-img-item:nth-child(3) {

}

@keyframes bannermoveReverse {
0% {
    transform: translateX(-50%);
}
100% {
    transform: translateX(0);
}
}
.course-cards-main-container {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-color: var(--main-bg-color);
}

.course-cards-wrap {
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px)  {
    .course-cards-main-container {
        display: flex;
        height: auto;
    }
    .course-cards-wrap {
        display: flex;
        flex-direction: column;
    }
}
.career-route-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 20vw;
    max-width: 20vw;
    min-height: max-content;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 1vw;
    margin-left: 1vw;
}

.career-route-card-img-block{
    border-radius: 50%;
    overflow: hidden;
    margin-top: 1vh;
}

.career-route-card-img {
    width: 100px;
    height: 100px;
}

.career-route-card-name {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: var(--titles-color);
    margin: 10px 10px 1vh 10px;
}

.career-route-card-btn{
    background-color: var(--main-btn-bg-color);
    font-size: 1rem;
    margin-top: 1vh;
    padding: 10px 20px 10px 20px;
    margin: 2vh 0 1vh 0;
    border-radius: 10px;
    color: var(--secundary-btn-txt-color);
    border: 2px solid var(--main-btn-border-color);
    cursor: pointer;
}

.career-route-card-btn:hover{
    background-color: var(--btn-bg-color-hover);
    color: var(--main-btn-txt-color-hover);
}


@media (max-width: 600px)  { 
    .career-route-card-container{
        min-width: 80vw;
        max-width: 80vw;
        margin-bottom: 2vh;
    }

    .career-route-card-name {
        margin: 10px 10px 2vh 10px;
    }
}
.WhatsAppButton {
    position: fixed;
    bottom: 200px;
    right: 20px;
    border: 0px;
    cursor: pointer;
    transform: translateY(100px);
    transition: 0.3s ;
    width: 70px;
    height: 70px;
    background-size: contain;
    overflow: hidden;
    z-index: 5;
}

.WhatsAppButton:hover{
    width: 72px;
    height: 72px;
    color: white;
}
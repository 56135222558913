html{
    scroll-behavior: smooth;
 }

.btn-scrolltop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 20px 23px;
    border: 1px solid white;
    border-radius: 100%;
    cursor: pointer;
    transform: translateY(100px);
    transition: 0.3s ;
    color: #c3c2c7;
    z-index: 5;
}

.btn-scrolltop-on {
    transform: translateY(0);
}


.btn-scrolltop:hover{
    background-color: var(--btn-bg-color-hover);
    color: white;
}
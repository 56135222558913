.navbar-web {
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
    background: var(--main-bg-color);
    position: fixed;
    z-index: 1000;
    box-sizing: border-box;
}

.navbar-logo{
    height:75px;
    width: auto;
    letter-spacing: 1.5px;
    cursor: pointer;
}

.hamb-menu {
    display: none;
}

.navbar-web-items {
  display: flex;
  flex-direction: row;
}

.navbar-web-item, .navbar-web-item a {
    list-style: none;
    padding: 0px 20px ;
    font-size: 16px;
    font-weight: 500;
    color: var(--nav-menu-color);
    text-decoration: none;
    cursor: pointer;
}

.navbar-web-item a {
  padding: 0;
}

.navbar-web-sub-item {
  font-size: 16px;
  font-weight: 500;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.navbar-web-sub-item:hover {
  text-decoration: underline;
}

.navbar-web-courses-list {
  display: none;
  list-style: none;
  position: absolute;
  background-color: white;
  top: 75px;
  left: 0;
  width: 100vw;
  height: auto;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 16px 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition:  all 0.4s ease 0s;
}

.navbar-courses-list-container {
  margin: 1vw 4vw 4vw 4vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-courses-list-block {

}

.navbar-courses-list-block-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--titles-color);

}

.navbar-web-courses-list-hover{
  display: block;
}

.navbar-regular-button {
    font-size: 16px;
    font-weight: 500;
    color: var(--main-btn-txt-color);
    text-decoration: none;
    cursor: pointer;
    background: var(--main-btn-bg-color);
    border: 2px solid var(--main-btn-border-color);
    border-radius: 50px;
    padding: 9px 25px;
}

.sidenav {
  display: none;
}

.navbar-web-item-hover {
    color: var(--nav-menu-color-hover);
    transition:  all 0.4s ease 0s;
}

.navbar-regular-button:hover{
    
    background-color: var(--btn-bg-color-hover);
    transition:  all 0.4s ease 0s;
    color: black;
}

.navbar-join-btn {
  text-decoration: none;
  color: var(--main-btn-txt-color);
}

.navbar-join-btn:hover {
  text-decoration: none;
  color: black;
  transition:  all 0.4s ease 0s;
}

@media (max-width: 600px)  {
    .navbar-web {
        padding: 30px 7%;
    }

    .navbar-web ul {
        display: none;
    }

    .navbar-logo{
        /* margin-left: 40px; */
        height:auto;
        width: 48vw;
    }

    .hamb-menu {
        display: block;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 0;
        width: 40px;
        height: auto;
    }

    .line {
        fill: none;
        stroke: white;
        stroke-width: 2;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }

      .line2 {
        stroke-dasharray: 40 60;
        stroke-width: 6;
      }

      .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }

      .opened .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }

      .opened .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }

      .opened .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }

      .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 74px;
        left: 0;
        background-color: rgba(255,255, 255, 1);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        display: block;
      }
    
      .sidenavOpen {
        width: 100%;
      }

      .sidenavOpen-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 1vh 5vw 1vh 5vw;
      }

      .sidenav-mobile-block-title {
        font-size: 22px;
        font-weight: 700;
        color: var(--green-main);
      }

      .sidenav-mobile-main-info-block {
        margin-top: 2vh;
        margin-bottom: 2vh;
      }

      .sidenav-mobile-learning-paths-block {
        margin-top: 2vh;
        margin-bottom: 2vh;

      }

      .sidenav-mobile-resources-block {
        margin-top: 2vh;
        margin-bottom: 2vh;
      }
      
      .item {
        padding: 8px 8px 8px 0px;
        text-decoration: none;
        font-size: 18px;
        color: #818181;
        display: block;
        transition: 0.3s;
      }
      
      .item:hover {
        color: var(--green-main);
      }
      
    
}

